import React from "react";
import MasterHeader from "../components/MasterHeader";
import MasterFooter from "../components/MasterFooter";
import Layout from "../components/layout";
import Seo from "../components/seo";
import VimeoVideo from "../components/VimeoVideo";
import Filter from "../components/Filter";
import "./videos.css";

const VideosPage = (props) => {
  return (
    <Layout>
      <Seo title="Home" />
      <MasterHeader />
      <Filter tag="videos" show={true} />
      <div className="video-container">
        <VimeoVideo url="https://player.vimeo.com/video/932989621?h=938e6e6265"/>
        <VimeoVideo url="https://player.vimeo.com/video/933019310?h=ad63987bdf"/>
      </div>
      <MasterFooter />
    </Layout>
  )
}

export default VideosPage;