import React from "react";

export default function VimeoVideo({url}) {
  let style = {
    width: "100%",
    maxWidth: "1280px",
    aspectRatio: "16/9",
    margin: "auto"
  }
  return (
    <iframe 
      src={url} 
      style={style}
      frameBorder="0" 
      allow="autoplay; fullscreen" 
      allowFullscreen>
    </iframe>
  )
}